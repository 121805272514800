// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-about-page-js": () => import("/builds/nyaladev/ssc/ssc-frontend/src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-art-homepage-js": () => import("/builds/nyaladev/ssc/ssc-frontend/src/templates/art-homepage.js" /* webpackChunkName: "component---src-templates-art-homepage-js" */),
  "component---src-templates-ebook-view-js": () => import("/builds/nyaladev/ssc/ssc-frontend/src/templates/ebook-view.js" /* webpackChunkName: "component---src-templates-ebook-view-js" */),
  "component---src-templates-books-page-js": () => import("/builds/nyaladev/ssc/ssc-frontend/src/templates/books-page.js" /* webpackChunkName: "component---src-templates-books-page-js" */),
  "component---src-templates-index-page-js": () => import("/builds/nyaladev/ssc/ssc-frontend/src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-magazine-page-js": () => import("/builds/nyaladev/ssc/ssc-frontend/src/templates/magazine-page.js" /* webpackChunkName: "component---src-templates-magazine-page-js" */),
  "component---src-templates-news-page-js": () => import("/builds/nyaladev/ssc/ssc-frontend/src/templates/news-page.js" /* webpackChunkName: "component---src-templates-news-page-js" */),
  "component---src-templates-single-article-js": () => import("/builds/nyaladev/ssc/ssc-frontend/src/templates/single-article.js" /* webpackChunkName: "component---src-templates-single-article-js" */),
  "component---src-templates-gallery-js": () => import("/builds/nyaladev/ssc/ssc-frontend/src/templates/gallery.js" /* webpackChunkName: "component---src-templates-gallery-js" */),
  "component---src-templates-author-page-js": () => import("/builds/nyaladev/ssc/ssc-frontend/src/templates/author-page.js" /* webpackChunkName: "component---src-templates-author-page-js" */),
  "component---src-templates-tag-page-js": () => import("/builds/nyaladev/ssc/ssc-frontend/src/templates/tag-page.js" /* webpackChunkName: "component---src-templates-tag-page-js" */),
  "component---src-pages-404-js": () => import("/builds/nyaladev/ssc/ssc-frontend/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-auth-connect-google-js": () => import("/builds/nyaladev/ssc/ssc-frontend/src/pages/auth/connect/google.js" /* webpackChunkName: "component---src-pages-auth-connect-google-js" */),
  "component---src-pages-auth-index-js": () => import("/builds/nyaladev/ssc/ssc-frontend/src/pages/auth/index.js" /* webpackChunkName: "component---src-pages-auth-index-js" */),
  "component---src-pages-auth-members-add-article-js": () => import("/builds/nyaladev/ssc/ssc-frontend/src/pages/auth/members/AddArticle.js" /* webpackChunkName: "component---src-pages-auth-members-add-article-js" */),
  "component---src-pages-auth-members-approve-articles-js": () => import("/builds/nyaladev/ssc/ssc-frontend/src/pages/auth/members/ApproveArticles.js" /* webpackChunkName: "component---src-pages-auth-members-approve-articles-js" */),
  "component---src-pages-auth-members-rich-text-editor-js": () => import("/builds/nyaladev/ssc/ssc-frontend/src/pages/auth/members/RichTextEditor.js" /* webpackChunkName: "component---src-pages-auth-members-rich-text-editor-js" */),
  "component---src-pages-blog-index-js": () => import("/builds/nyaladev/ssc/ssc-frontend/src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-contact-index-js": () => import("/builds/nyaladev/ssc/ssc-frontend/src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */)
}

